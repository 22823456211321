import AttachmentModel from "./AttachmentModel";
export default class TicketModel {
  id: string | null;
  subject: string | null;
  description: string | null;
  machines_id: string | null;
  ticket_categories_id: string | null;
  urgency_level: string | null;
  tiket_status: string | null;
  assign_to: string | null;
  json_meta: string | null;
  issued_by: string | null;
  attachment: AttachmentModel[];
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  constructor() {
    this.id = null;
    this.subject = null;
    this.description = null;
    this.machines_id = null;
    this.ticket_categories_id = null;
    this.urgency_level = null;
    this.tiket_status = null;
    this.assign_to = null;
    this.json_meta = null;
    this.issued_by = null;
    this.attachment = [];
    this.deleted_at = null;
    this.created_at = null;
    this.updated_at = null;
  }
}
