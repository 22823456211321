






















































































import Vue from "vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import Search from "@/components/Search.vue";
import { Component } from "vue-property-decorator";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import api from "@/api";
import TicketModel from "@/models/createTicket/TicketModel";
import utils from "@/utils";
@Component({
  components: {
    SubHeader,
    Search,
    MenuFooter,
  },
})
export default class ListNotification extends Vue {
  mounted() {
    this.getDetailTicket(this.$route.params.id);
  }
  utils = utils;
  titlePage = "Detail Ticket";
  date = "21/JAN/2022";
  time = "12.30";
  title = "Cake Engine Breaking Down";
  subTitle = "Lorem Ipsum is simply text";
  status = "new";
  isOpen = "0";
  // listImg = [
  //   {
  //     id: "1",
  //     nama_file: "Mesin 1.jpeg",
  //   },
  //   {
  //     id: "2",
  //     nama_file: "Mesin 2.jpeg",
  //   },
  //   {
  //     id: "3",
  //     nama_file: "Mesin 3.jpeg",
  //   },
  //   {
  //     id: "4",
  //     nama_file: "Mesin 4.jpeg",
  //   },
  //   {
  //     id: "5",
  //     nama_file: "Mesin 5.jpeg",
  //   },
  // ];
  ticket: TicketModel = new TicketModel();
  getDetailTicket(idTicket: string) {
    return new Promise((resolve, reject) => {
      api.ticket
        .getListProgress(idTicket)
        .then((response) => {
          if (response.data.code === 200) {
            this.ticket = Object.assign({}, response.data.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }
  get colorStatus() {
    if (this.ticket.tiket_status === "new") {
      return "cl-red";
    } else {
      return "cl-green-light";
    }
  }
  get pitaStatus() {
    if (this.ticket.tiket_status === "new") {
      return "new";
    } else {
      return "on-progress";
    }
  }
}
